/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

 import "firebase/firestore";
import "./src/styles/global.css";
require("typeface-roboto");
export { wrapRootElement } from "./plugins/custom-mui-theme/wrapRootElement";
